import { gql } from '@apollo/client'

const PUBLIC_CREATE_USER = gql`
  mutation PublicCreateUser($email: String!, $password: String!, $code: String) {
    publicCreateUser(email: $email, password: $password, code: $code) {
      id
      createdAt
      updatedAt
      email
    }
  }
`

const PUBLIC_USER_VERIFY_EMAIL = gql`
  mutation PublicUserVerifyEmail($token: String!) {
    publicUserVerifyEmail(token: $token) {
      id
      updatedAt
      email
      emailVerified
    }
  }
`

const PUBLIC_USER_REQUEST_PASSWORD_RESET = gql`
  mutation PublicUserRequestPasswordReset($email: String!) {
    publicUserRequestPasswordReset(email: $email)
  }
`

const PUBLIC_USER_PASSWORD_RESET = gql`
  mutation PublicUserPasswordReset($token: String!, $password: String!) {
    publicUserPasswordReset(token: $token, password: $password) {
      email
    }
  }
`

const PUBLIC_USER_VERIFY_RESET_PASSWORD_TOKEN = gql`
  query PublicUserVerifyResetPasswordToken($token: String!) {
    publicUserVerifyResetPasswordToken(token: $token)
  }
`

export {
  PUBLIC_CREATE_USER,
  PUBLIC_USER_REQUEST_PASSWORD_RESET,
  PUBLIC_USER_PASSWORD_RESET,
  PUBLIC_USER_VERIFY_RESET_PASSWORD_TOKEN,
  PUBLIC_USER_VERIFY_EMAIL,
}
