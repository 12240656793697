import { gql } from '@apollo/client'

export const SERVICE_MESSAGE = gql`
  query ServiceMessage {
    currentServiceStatus  {
      id
      createdAt
      updatedAt
      from
      to
      status
      message
    }
  }
`
