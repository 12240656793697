import { gsap } from 'gsap'

export const duration = 0.3
export const stagger = 0.08
export const desert = duration - stagger * 2

export function enter(node) {
  gsap.from(node, {
    duration: 0.5,
    autoAlpha: 0,
    y: -30,
  })
}

export function exit(node) {
  gsap.to(node, {
    duration: 0.5,
    autoAlpha: 0,
    y: -30,
  })
}
