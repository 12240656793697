import jwtDecode, { JwtPayload } from 'jwt-decode'

import { isDevEnvironment } from './utils'

// global variable holding access token in memory
let accessToken = ''

function getAccessToken() {
  let at = accessToken

  if (isDevEnvironment()) {
    const savedAt = localStorage.getItem('justdrive_accessToken')
    if (savedAt && !accessToken) {
      at = savedAt
    }
  }

  return at
}

function setAccessToken(token) {
  accessToken = token
  if (isDevEnvironment()) {
    localStorage.setItem('justdrive_accessToken', token)
  }
}

function isTokenValid() {
  const token = getAccessToken()
  if (token === '') return false

  try {
    // decode token
    const decoded = jwtDecode<any>(token) // TODO:
    if (!decoded || typeof decoded !== 'object') return false
    if (!decoded.exp) return false

    // check expiry
    if (Date.now() >= decoded.exp * 1000) {
      return false
    }
  } catch (err) {
    console.error(err)
  }

  return true
}

function decodeToken(token) {
  try {
    return jwtDecode(token)
  } catch (error) {
    console.error(error)
  }
}

function authBaseUrl(): string {
  const url = isDevEnvironment() ? process.env.NEXT_PUBLIC_AUTH_URL_DEV : process.env.NEXT_PUBLIC_AUTH_URL_PROD
  return url
}

async function fetchAccessToken() {
  const url = authBaseUrl()

  return await fetch(`${url}/refresh`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
  })
}

export { getAccessToken, setAccessToken, isTokenValid, fetchAccessToken, decodeToken, authBaseUrl }
