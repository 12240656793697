import { TokenRefreshLink } from 'apollo-link-token-refresh'

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from } from '@apollo/client'

import { fetchAccessToken, getAccessToken, isTokenValid, setAccessToken } from '../utils/accessToken'
import { isDevEnvironment } from '../utils/utils'

const refreshMiddleware = new TokenRefreshLink({
  isTokenValidOrUndefined: isTokenValid,
  fetchAccessToken: fetchAccessToken,
  handleFetch: accessToken => {
    setAccessToken(accessToken)
  },
  handleError: err => {
    // full control over handling token fetch Error
    console.warn('Your refresh token is invalid. Try to relogin')
    console.error(err)
  },
})

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const accessToken = getAccessToken()
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: accessToken,
      tenant_id: process.env.NEXT_PUBLIC_TENANT_ID,
    },
  }))

  return forward(operation)
})

const httpMiddleware = new HttpLink({
  uri: isDevEnvironment() ? process.env.NEXT_PUBLIC_BACKEND_URL_DEV : process.env.NEXT_PUBLIC_BACKEND_URL_PROD,
  credentials: 'include',
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  ssrMode: false,
  link: from([refreshMiddleware, authMiddleware, httpMiddleware]),
})

// export const publicClient = new ApolloClient({
//   cache: new InMemoryCache(),
//   ssrMode: true,
//   link: from([refreshMiddleware, authMiddleware, httpMiddleware]),
// })
