export const isDevEnvironment: () => boolean = () => {
  return process && process.env.NODE_ENV === 'development'
}

export const formatPrice = (amount: number) => {
  if (!amount) return false

  const price = new Intl.NumberFormat('da-DK', { style: 'currency', currency: 'DKK', maximumFractionDigits: 0 }).format(amount)
  return price
}

export const formatNumber = (num: number) => {
  const formattedNum = new Intl.NumberFormat('da-DK', { maximumFractionDigits: 0 }).format(num)
  return formattedNum
}

export const formatDealershipAddress = dealership => {
  if (!dealership) return false

  const name = dealership.name
  const address = dealership.address
    ? `, ${dealership.address.street}, ${dealership.address.postal} ${dealership.address.city}`
    : ''

  return name + address
}

export const shuffleArray = (array: any[]) => {
  const shuffled = array
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)

  return shuffled
}
