import { gql } from '@apollo/client'

const USER_CREATE_ADDRESS = gql`
  mutation UserCreateAddress($isDefault: Boolean!, $city: String!, $postal: String!, $street: String!, $floor: String) {
    userCreateAddress(isDefault: $isDefault, city: $city, postal: $postal, street: $street, floor: $floor) {
      id
      city
      postal
      street
      floor
    }
  }
`

const USER_UPDATE_ADDRESS = gql`
  mutation UserUpdateAddress($id: ID!, $isDefault: Boolean, $street: String, $floor: String, $city: String, $postal: String) {
    userUpdateAddress(id: $id, isDefault: $isDefault, city: $city, postal: $postal, street: $street, floor: $floor) {
      id
      city
      postal
      street
      floor
    }
  }
`

export { USER_CREATE_ADDRESS, USER_UPDATE_ADDRESS }
