import cn from 'classnames'
import { gsap } from 'gsap'
import Link from 'next/link'
import React, { useEffect } from 'react'

import { track } from '../tracking'
import { duration, stagger } from '../utils/animation'
import splitText from '../utils/splitText'
import ActiveLink from './ActiveLink'
import { SubstoresLogo } from './SubstoresLogo'
import { substoreSettings } from '../utils/substores.config'

interface Props {
  // accordionItems: AccordionItem[]
}

const nav = [
  {
    title: 'Biler',
    href: '/listing',
    // onClick: () => track('SEE_CARS_BUTTON_CLICKED', { placement: 'header' }),
  },
  { title: 'Konceptet', href: '/hvordan-virker-det' },
  { title: 'Hjælp', href: '/fa-hjaelp-her' },
  { title: 'Om os', href: '/om-os' },
]

const Header = (props: Props) => {
  const comp: undefined | React.Ref<HTMLElement> = React.useRef()
  const tl = React.useRef<gsap.core.Timeline>()

  const [activeMobileNav, setActiveMobileNav] = React.useState(false)

  React.useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const curRef = comp.current

      let mm = gsap.matchMedia()

      mm.add('(max-width: 1024px)', () => {
        tl.current = gsap.timeline({ paused: true })

        splitText('.js-mobileNav-a', curRef)

        tl.current.to('.js-mobileNav', {
          x: '0%',
          ease: 'power3.out',
          duration: 0.6,
        })

        tl.current.from(
          '.js-mobileNav-a-line',
          {
            y: '100%',
            x: 20,
            // opacity: 0,
            ease: 'power3.out',
            duration: 0.3,
            stagger: stagger * 1.1,
          },
          `>-=0.4`
        )

        tl.current.to(
          '.js-burgerLine-3',
          {
            scaleX: 0,
            duration: 0.3,
            ease: 'power3.out',
          },
          '<'
        )

        tl.current.to(
          '.js-burgerLine-1',
          {
            rotate: '45deg',
            y: 10,
            duration: 0.8,
            ease: 'power3.inOut',
          },
          '<+=0.08'
        )
        tl.current.to(
          '.js-burgerLine-2',
          {
            rotate: '-45deg',
            // y: -5,
            duration: 0.8,
            ease: 'power3.inOut',
          },
          '<'
        )
      })
    }, comp)

    return () => {
      ctx.revert()
    }
  }, [])

  const toggleBurger = (bool?: boolean) => (e: any) => {
    setActiveMobileNav(!activeMobileNav)
    if (tl.current) {
      if (typeof bool === 'undefined') {
        if (activeMobileNav) tl.current.reverse()
        else tl.current.play()
      } else {
        if (bool) tl.current.play()
        else tl.current.reverse()
      }
    }
  }

  return (
    <header
      ref={comp}
      className={cn('max-md:relative header flex flex-col items-center justify-between w-full', {
        'relative max-md:z-[100]': activeMobileNav,
      })}
    >
      <div
        className={cn('fixed max-md:scale-75 z-50 flex top-5 left-4 md:left-8 transition origin-left mix-blend-difference', {})}
      >
        <Link legacyBehavior href="/">
          <a onClick={toggleBurger(false)}>
            {substoreSettings ? (
              <>
                <SubstoresLogo subdomain={substoreSettings.subdomain} logoType="full" />
              </>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="174" height="26" viewBox="421.34 458.62 1062.82 162.77">
                <defs></defs>
                <path d="M692.67,580.86c0,16.11-10.82,22.79-23.25,22.79h-.46c-12.89,0-23.25-6.68-23.25-22.79v-16.34l-20.26-.23v14.96c0,28.77,20.03,42.13,43.51,42.13s43.97-13.35,43.97-42.13v-113.72h-20.26v115.33Z" />
                <path d="M810.66,576.02c0,16.57-12.89,27.85-29.23,27.85h-.46c-15.88,0-25.55-7.83-25.55-24.4v-75.96h-20.26v78.26c0,31.08,20.03,39.59,38.9,39.59s32-8.75,35.91-20.03l2.99,.69c-2.3,5.06-2.99,8.29-2.99,13.81v2.76h20.95v-115.1h-20.26v72.51Z" />
                <path d="M909.3,553.7l-23.71-5.52c-11.28-2.53-14.5-8.52-14.5-14.96,0-9.67,8.75-15.42,22.1-15.42,11.97,0,24.86,6.68,26.01,23.48h21.41c-.69-24.63-20.26-40.51-46.5-40.51s-43.97,12.2-43.97,33.15c0,15.19,7.83,26.01,27.39,30.62l22.56,5.29c13.35,3.22,20.03,8.29,20.03,17.72,0,10.82-11.05,16.8-25.32,16.8-16.11,0-27.62-10.13-28.54-25.78h-21.18c.23,20.72,15.19,42.82,49.72,42.82,28.77,0,46.27-13.12,46.27-35.45,0-17.72-12.66-27.85-31.77-32.23Z" />
                <path d="M982.98,586.61v-66.3h20.49v-16.8h-20.49v-32.23h-20.26v32.23h-16.57v16.8h16.57v64.22c0,21.18,7.14,35.45,28.77,35.45,4.6,0,10.82-1.15,14.04-2.07v-17.95s-5.76,1.38-11.05,1.38c-9.67,0-11.51-5.98-11.51-14.73Z" />
                <path d="M1100.54,505.12c0,6.22,.46,10.59,2.53,17.96l-2.76,.69c-5.06-12.66-18.65-23.02-38.67-23.02-26.01,0-50.87,19.8-50.87,60.54s24.86,60.08,50.87,60.08c20.03,0,33.61-9.44,38.67-22.33l2.76,.92c-1.84,5.75-2.53,11.51-2.53,15.42v3.22h20.26v-159.98h-20.26v46.5Zm-32.92,98.98h-1.84c-22.56,0-33.84-17.03-33.84-42.82s11.28-43.05,33.84-43.05h1.84c22.56,0,34.53,17.26,34.53,43.05s-11.97,42.82-34.53,42.82Z" />
                <path d="M1163.15,525.61l-2.53-.69c1.84-6.45,2.53-10.59,2.53-16.34v-5.06h-20.26v115.1h20.26v-64.68c0-22.1,15.88-29.23,30.85-29.23,4.37,0,7.37,.23,11.51,.92l.23-22.1h-3.91c-17.96,0-31.31,4.37-38.67,22.1Z" />
                <rect x="1214.85" y="503.51" width="20.26" height="115.1" />
                <path d="M1225.21,458.63c-7.6,0-13.58,5.98-13.58,13.35s5.98,13.58,13.58,13.58,13.12-5.98,13.12-13.58-5.76-13.35-13.12-13.35Z" />
                <path d="M1330.36,503.51l-19.8,55.71c-5.29,15.19-8.52,28.08-11.51,42.13h-2.99c-2.99-13.58-6.45-26.01-11.51-40.97l-19.57-56.86h-21.41l40.05,115.1h27.16l40.97-115.1h-21.41Z" />
                <path d="M1405.7,604.34h-1.38c-18.88,0-32.69-13.81-33.61-37.29v-1.15h85.86v-5.29c0-36.83-20.49-59.85-52.71-59.85s-53.87,23.48-53.87,60.31,19.8,60.08,53.87,60.31c30.39,.23,49.26-17.96,54.09-42.82h-21.18c-3.68,14.73-14.04,25.78-31.08,25.78Zm-3.45-86.55h1.84c18.19,0,29.23,14.27,31.08,31.31h-63.53c1.84-16.57,12.43-31.31,30.62-31.31Z" />
                <path d="M538.9,464.6c-.71-.85-1.76-1.33-2.86-1.33h-112.83c-1.59,0-2.45,1.85-1.43,3.07l56.85,67.73c3.94,4.59,4.82,6.23,12.74,6.23h53.25v4.05h-53.25c-8.01,0-8.81,1.63-12.74,6.22l-56.85,67.74c-1.02,1.22-.16,3.07,1.43,3.07h112.83c1.1,0,2.15-.49,2.86-1.33l63.75-75.98c.85-1.01,.85-2.48,0-3.48l-63.75-75.98Z" />
                <path d="M1473.28,489.54v-.08c1.32,0,2.96-1.36,2.96-3.44,0-2.8-2.24-4.12-4.96-4.12h-6.32v14.76h2.36v-6.04h4.08c1.72,0,2,.44,2,2.64,0,1.64,.32,2.68,.64,3.4h2.64v-.08c-.6-.88-.92-2.16-.92-3.84,0-1.96-.4-3.12-2.48-3.2Zm-2.68-.96h-3.28v-4.72h3.56c1.8,0,2.92,.36,2.92,2.32s-1.24,2.4-3.2,2.4Z" />
                <path d="M1470.4,475.74c-7.4,0-13.72,5.32-13.72,13.84s6.32,13.88,13.72,13.88,13.76-5.36,13.76-13.88-6.28-13.84-13.76-13.84Zm0,25c-5.88,0-11-4.36-11-11.16s5.12-11.12,11-11.12,11.04,4.32,11.04,11.12-5.08,11.16-11.04,11.16Z" />
              </svg>
            )}
          </a>
        </Link>
      </div>

      <nav className={cn('nav fixed z-40 flex items-center lg:mix-blend-difference lg:text-white top-5 right-4 md:right-8')}>
        <div
          className={cn(
            'js-mobileNav max-lg:fixed max-lg:w-full max-lg:h-screen max-lg:bg-green-500 max-lg:left-0 max-lg:top-0 max-lg:pt-40 md-max-lg::pt-60 max-lg:pl-4 max-lg:translate-x-full'
          )}
        >
          {nav.map(el => (
            <ActiveLink
              key={el.title}
              href={el.href}
              className={cn(
                'js-mobileNav-a relative block lg:inline-block text-6xl lg:text-lg mb-6 lg:mb-0 lg:ml-20 xl:ml-32',
                'after:absolute after:w-full after:h-[1px] after:left-0 after:bottom-0 lg:after:bg-white after:origin-bottom-right after:transition after:duration-300 after:scale-x-0 hover:after:scale-x-100 hover:after:origin-bottom-left'
              )}
              activeClassName="after:scale-x-100"
              // onClick={el.onClick}
              onClick={toggleBurger(false)}
            >
              {el.title}
            </ActiveLink>
          ))}
        </div>
        <Link legacyBehavior href="/account">
          <a onClick={toggleBurger(false)} className="z-50 lg:ml-20 xl:ml-32 bg-white text-black py-1 px-4 rounded-full">
            Min side
          </a>
          {/* bg-[#3E0080] */}
        </Link>

        <button className="lg:hidden ml-6 z-50" onClick={toggleBurger()}>
          <div className="space-y-2">
            <span className="js-burgerLine-1 block w-8 h-0.5 bg-gray-600"></span>
            <span className="js-burgerLine-2 block w-8 h-0.5 bg-gray-600"></span>
            <span className="js-burgerLine-3 block w-5 h-0.5 bg-gray-600"></span>
          </div>
        </button>
      </nav>
    </header>
  )
}

export default Header
