import { gql } from '@apollo/client'

const USER_CREATE_PAYMENT_METHOD = gql`
  mutation UserCreatePaymentMethod($paymentProcessorId: String!, $isDefault: Boolean!, $customerId: ID) {
    userCreatePaymentMethod(paymentProcessorId: $paymentProcessorId, isDefault: $isDefault, customerId: $customerId) {
      id
      createdAt
      updatedAt
      paymentProcessorId
    }
  }
`

const USER_CREATE_SETUP_INTENT = gql`
  mutation UserCreateSetupIntent($customerId: ID) {
    userCreateSetupIntent(customerId: $customerId)
  }
`

const USER_DELETE_PAYMENT_METHOD = gql`
  mutation UserDeletePaymentMethod($id: Int!) {
    userDeletePaymentMethod(id: $id) {
      id
    }
  }
`

const USER_SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation UserSetDefaultPaymentMethod($id: Int!) {
    userSetDefaultPaymentMethod(id: $id) {
      id
    }
  }
`

export {
  USER_CREATE_PAYMENT_METHOD,
  USER_CREATE_SETUP_INTENT,
  USER_DELETE_PAYMENT_METHOD,
  USER_SET_DEFAULT_PAYMENT_METHOD,
}
