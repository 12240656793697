import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, useEffect, useState } from 'react'

type ActiveLinkProps = LinkProps & {
  className?: string
  activeClassName: string
}

const ActiveLink = ({ children, activeClassName, className, onClick, ...props }: PropsWithChildren<ActiveLinkProps>) => {
  const { asPath, isReady } = useRouter()
  const [computedClassName, setComputedClassName] = useState(className)

  useEffect(() => {
    if (isReady) {
      const linkPathname = new URL((props.as || props.href) as string, location.href).pathname
      const activePathname = new URL(asPath, location.href).pathname

      const newClassName = linkPathname === activePathname ? `${className} ${activeClassName}`.trim() : className

      if (newClassName !== computedClassName) {
        setComputedClassName(newClassName)
      }
    }
  }, [asPath, isReady, props.as, props.href, activeClassName, className, computedClassName])

  return (
    <Link className={computedClassName} onClick={onClick} {...props}>
      {/* {React.cloneElement(children as any, { className: computedClassName, onClick })} */}
      {children}
    </Link>
  )
}

export default ActiveLink
