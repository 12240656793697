import { AccordionItemProps, AccordionItem as Item } from '@szhsin/react-accordion'
import cn from 'classnames'
import React from 'react'

type Props = AccordionItemProps

const AccordionItem = ({ header, ...rest }: Props) => {
  return (
    <Item
      {...rest}
      header={({ state: { isEnter: expanded } }) => (
        <>
          {header}
          <div className={`ml-auto transition-transform duration-500 ease-in-out ${expanded && 'rotate-180'}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-chevron-down w-3"
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path
                d="M11.2854 0.858398L9.25738 0.858398L9.25737 18.3564C8.11338 15.1064 4.55138 13.1824 0.989376 13.0004L0.989375 15.2364C5.64338 15.5224 8.81538 18.6684 9.36137 23.0624L11.2074 23.0624C11.7534 18.6684 14.9774 15.3924 19.5794 15.2364L19.5794 13.0004C16.0174 13.1824 12.5334 15.1064 11.2854 18.3564L11.2854 0.858398Z"
                fill="black"
              />
            </svg>
          </div>
        </>
      )}
      className="border-b first-of-type:border-t border-neutral-400"
      buttonProps={{ className: `flex w-full py-6 text-left transition` }}
      contentProps={{
        className: ({ isEnter: expanded }) =>
          cn('transition-height duration-200 ease-in-out', { 'accordionItem-content-active': expanded }),
      }}
      panelProps={{ className: ({ isEnter: expanded }) => cn('pt-2 pb-8', { 'accordionItem-panel-active': expanded }) }}
    />
  )
}

export default AccordionItem
