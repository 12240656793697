type InternalSubstores = {
  [key: string]: {
    subdomain: string
    name: string
    brandName: string
    legalName: string
    cvr: string
    contactEmail: string
    primaryColor: string
    secondaryColor: string
    contrastColor: string
    logoUrl: string
    locations: readonly number[]
    address: {
      street: string
      number: string
      city: string
      zipCode: string
    }
    gtmId: string | null
  }
}
export type Substore = typeof substores

export const substores = {
  'nissan-danmark': {
    subdomain: 'nissan-danmark',
    name: 'Nissan Danmark',
    brandName: 'Nissan Drive',
    legalName: 'Nissan og Justdrive',
    cvr: '42078395',
    contactEmail: 'hej+nissan@justdrive.today',
    primaryColor: '#C3002F',
    secondaryColor: '#FFFFFF',
    contrastColor: '#FFFFFF',
    // secondaryColor: '#5c5c5c',
    logoUrl: 'https://ruwqcqtlonwdksotboxk.supabase.co/storage/v1/object/public/logos/Nissan-Logo.png',
    locations: [],
    address: {
      street: 'Lyngbyvej',
      number: '24',
      city: 'København Ø',
      zipCode: '2100',
    },
    gtmId: 'M599TBSH',
  },
} as const satisfies InternalSubstores

const getCurrentSubstore = () => {
  const subdomain = process.env.NEXT_PUBLIC_SUBDOMAIN as keyof Substore
  if (!subdomain) return null
  return substores[subdomain] ?? null
}

export const substoreSettings = getCurrentSubstore()

export const getBrandName = (alternative: string) => {
  return substoreSettings?.brandName ?? alternative
}

// Utility function to check for uniqueness of the `subdomain` field
function validateUniqueSubdomains(stores: Substore): boolean {
  const subdomainSet = new Set<string>()
  for (const store in stores) {
    if (subdomainSet.has(store)) {
      throw new Error(`Duplicate subdomain found: ${store}`)
    }
    subdomainSet.add(store)
  }
  return true
}

// Call this to validate
validateUniqueSubdomains(substores)
