import { gql } from '@apollo/client'

const USER_CREATE_CUSTOMER = gql`
  mutation UserCreateCustomer(
    $isDefault: Boolean!
    $firstName: String!
    $lastName: String!
    $phone: String!
    $birthdate: Date!
    $socialSecurityDigits: String!
    $licenseNumber: String!
    $licenseExpiry: Date
  ) {
    userCreateCustomer(
      isDefault: $isDefault
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      birthdate: $birthdate
      socialSecurityDigits: $socialSecurityDigits
      licenseNumber: $licenseNumber
      licenseExpiry: $licenseExpiry
    ) {
      id
      isDefault
      firstName
      lastName
      phone
      tier
      birthdate
      licenseNumber
      licenseExpiry
      paymentProcessorId

      user {
        id
        email
      }
    }
  }
`

const USER_UPDATE_CUSTOMER = gql`
  mutation UserUpdateCustomer(
    $id: ID!
    $isDefault: Boolean
    $firstName: String
    $lastName: String
    $phone: String
    $paymentProcessorId: String
    $birthdate: Date
    $licenseNumber: String
    $licenseExpiry: Date
    $socialSecurityDigits: String
    $street: String
    $floor: String
    $city: String
    $postal: String
  ) {
    userUpdateCustomer(
      id: $id
      isDefault: $isDefault
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      paymentProcessorId: $paymentProcessorId
      birthdate: $birthdate
      licenseNumber: $licenseNumber
      licenseExpiry: $licenseExpiry
      socialSecurityDigits: $socialSecurityDigits
      street: $street
      floor: $floor
      city: $city
      postal: $postal
    ) {
      id
      isDefault
      firstName
      lastName
      phone
      tier
      birthdate
      licenseNumber
      licenseExpiry
      paymentProcessorId
    }
  }
`

export { USER_CREATE_CUSTOMER, USER_UPDATE_CUSTOMER }
