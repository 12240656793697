import SplitText from 'split-type'

const splitText = (selectorClass: string, context?: HTMLElement) => {
  const s = selectorClass.slice(1)
  const lineSelector = `${s}-line`
  const lineWrapper = `${s}-wrapper`

  const selector = context ? (context.querySelectorAll(selectorClass) as ArrayLike<HTMLElement>) : selectorClass

  // prettier-ignore
  const wrappers = SplitText.create(selector, { types: 'lines', lineClass: `${lineWrapper} overflow-hidden leading-[inherit] text-[inherit]` })
  const lines = SplitText.create(wrappers.lines, { types: 'lines', lineClass: lineSelector })

  return [lines, wrappers]
}

export default splitText
