import { gql } from '@apollo/client'

export const WHO_AM_I = gql`
  query Whoami {
    whoami {
      id
      createdAt
      updatedAt
      archived
      email
      loyalty_level {
        name
        required_loyalty_points
        loyalty_level_discounts {
          discount_flat
        }
      }
      loyalty_points

      all_loyalty_levels {
        id
        name
        required_loyalty_points
        loyalty_level_discounts {
          discount_flat
        }
      }

      customer {
        id
        firstName
        lastName
        createdAt
        updatedAt
        archived
        birthdate
        socialSecurityDigits
        licenseNumber
        licenseExpiry
        address {
          id
          street
          floor
          city
          postal
        }
        addresses {
          id
          street
          floor
          city
          postal
        }
        phone
        paymentProcessorId
        paymentMethods {
          id
          createdAt
          updatedAt
          paymentProcessorId
          last4
          brand
          isDefault
        }
        reservations {
          id
          createdAt
          status
          listing {
            id
            name
          }
          pickUpAt
          returnAt
        }
      }

      customers {
        id
        firstName
        lastName
        createdAt
        updatedAt
        archived
        birthdate
        licenseNumber
        licenseExpiry
        address {
          street
          floor
          city
          postal
        }
        phone
        paymentProcessorId
        paymentMethods {
          id
          createdAt
          updatedAt
          paymentProcessorId
          last4
          brand
        }
        reservations {
          id
          createdAt
          listing {
            id
            name
          }
          pickUpAt
          returnAt
        }
      }
    }
  }
`
