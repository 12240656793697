import { Accordion } from '@szhsin/react-accordion'
import Link from 'next/link'
import React from 'react'

import { track } from '../tracking'
import { AccordionItemProps } from './Accordion'
import AccordionItem from './AccordionItem'
import NewsletterForm from './NewsletterForm'
import { getBrandName, substoreSettings } from '../utils/substores.config'
import { SubstoresLogo } from './SubstoresLogo'

interface Props {
  // accordionItems: AccordionItem[]
}

const Footer = (props: Props) => {
  const accordionItems: AccordionItemProps[] = [
    {
      title: `Hvorfor ${getBrandName('Justdrive')}?`,
      text: `${getBrandName(
        'Justdrive'
      )} er en ny bil med fuld fleksibilitet. Vi ved, at dine behov kan ændre sig, så hos os kan du nemt skifte din bil — uanset om den skal være større eller mindre. Vi leverer biler på abonnement i 120 dage. Det eneste du skal tænke på, er at køre.`,
    },
  ]

  return (
    <footer className="px-4 md:px-8 pt-20 pb-4 md:pt-32">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-6 gap-8">
        <div>
          <h4 className="pb-4 mb-8 border-b border-black md:max-w-[80%]">Info</h4>
          <ul className="list-none p-0 m-0">
            {substoreSettings ? (
              <>
                <li>{substoreSettings.name}</li>
                <li>
                  {substoreSettings.address.street} {substoreSettings.address.number}
                </li>
                <li>
                  {substoreSettings.address.zipCode} {substoreSettings.address.city}
                </li>
              </>
            ) : (
              <>
                <li>Justdrive ApS</li>
                <li>Filmbyen 30</li>
                <li>2650 Hvidovre</li>
              </>
            )}
          </ul>
        </div>
        <div>
          <h4 className="pb-4 mb-8 border-b border-black md:max-w-[80%]">Hjælp</h4>
          <ul className="list-none p-0 m-0">
            <li>
              {substoreSettings ? (
              <Link legacyBehavior href={`mailto:${substoreSettings.contactEmail}`}>
                <a className="">Kontakt</a>
              </Link>
              ) : (
              <Link legacyBehavior href="mailto:hej@justdrive.today">
                <a className="">Kontakt</a>
              </Link>
              )}
            </li>
            <li>
              <Link legacyBehavior href="/fa-hjaelp-her">
                <a className="">Hjælp</a>
              </Link>
            </li>
            <li>
              <Link legacyBehavior href="/skadeskatalog">
                <a className="">Skadeskatalog</a>
              </Link>
            </li>
          </ul>
        </div>
        <div>
          {/* TODO: Tenant name */}
          <h4 className="pb-4 mb-8 border-b border-black md:max-w-[80%]">{getBrandName('Justdrive.today')}</h4>
          <ul className="list-none p-0 m-0">
            <li>
              <Link legacyBehavior href="/listing">
                <a className="">Biler</a>
              </Link>
            </li>
            <li>
              <Link legacyBehavior href="/hvordan-virker-det">
                <a className="">Konceptet</a>
              </Link>
            </li>
            <li>
              <Link legacyBehavior href="/om-os">
                <a className="">Om os</a>
              </Link>
            </li>
            <br />
            <li>
              <Link legacyBehavior href="/betingelser">
                <a className="">Betingelser</a>
              </Link>
            </li>
            <li>
              <Link legacyBehavior href="/privatlivs-politik-hos-justdrive">
                <a className="">Privatlivspolitik</a>
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="pb-4 mb-8 border-b border-black md:max-w-[80%]">Min side</h4>
          <ul className="list-none p-0 m-0">
            <li>
              <Link legacyBehavior href="/account">
                <a className="">Min side</a>
              </Link>
            </li>
            <li>
              <Link legacyBehavior href="/account/signup">
                <a className="">Opret bruger</a>
              </Link>
            </li>
            <li>
              <Link legacyBehavior href="/account/login">
                <a className="">Login</a>
              </Link>
            </li>
          </ul>
        </div>
        {!substoreSettings && (
          <div>
            <h4 className="pb-4 mb-8 border-b border-black md:max-w-[80%]">Social medier</h4>
            <ul className="list-none p-0 m-0">
              <li>
                <Link legacyBehavior href="https://www.facebook.com/JustDriveToday">
                  <a target="_blank" className="">
                    Facebook
                  </a>
                </Link>
              </li>
              <li>
                <Link legacyBehavior href="https://www.instagram.com/justdrive.today/">
                  <a target="_blank" className="">
                    Instagram
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>

      <div className="mt-20 md:mt-40">
        <p className="text-2xl mb-8">Abonnér på vores nyhedsbrev her.</p>
        <div className="w-full md:grid grid-cols-2 gap-8">
          <NewsletterForm />

          <div className="mt-32 mb-10 md:my-0">
            <Accordion transition transitionTimeout={500} allowMultiple>
              {accordionItems.map(el => {
                return (
                  <AccordionItem
                    key={el.title}
                    header={el.title}
                    onClick={e => {
                      track('FOOTER_ACCORDION_TOGGLED', { title: el.title })
                    }}
                  >
                    <p>{el.text}</p>
                    {/* <PortableText value={} /> */}
                  </AccordionItem>
                )
              })}
            </Accordion>
          </div>
        </div>

        <div className="flex justify-between items-center md:items-end md:mt-28">
          <p>
            {getBrandName('Justdrive ApS')} © {new Date().getFullYear()} Alle rettigheder forbeholdes
          </p>
          <div className="flex items-end">
            <p className="hidden md:block mr-6 text-xl">Ny bil. Helt enkelt.</p>
            {substoreSettings ? (
              <SubstoresLogo subdomain={substoreSettings.subdomain} logoType="minimal" />
            ) : (
              <svg width="165" height="144" viewBox="0 0 165 144" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_756_1624)">
                  <path
                    d="M164.423 70.916L106.611 2.20007C105.967 1.4341 105.018 0.992188 104.016 0.992188H1.69764C0.260117 0.992188 -0.523628 2.66752 0.399931 3.76738L51.9558 65.0278C55.5279 69.1798 56.3215 70.6607 63.5131 70.6607H111.8V74.3237H63.5131C56.2487 74.3237 55.5279 75.8006 51.9558 79.9526L0.399931 141.217C-0.525597 142.317 0.258148 143.992 1.69764 143.992H104.018C105.02 143.992 105.969 143.55 106.613 142.784L164.425 74.0683C165.191 73.157 165.191 71.8293 164.425 70.918L164.423 70.916ZM116.762 1.55194H115.65V4.61585H114.97V1.55194H113.848V0.992188H116.762V1.55194ZM118.052 0.992188L118.554 2.64198C118.682 3.06425 118.82 3.5749 118.909 3.89897H118.958C119.037 3.58472 119.175 3.01515 119.283 2.64198L119.775 0.992188H120.829V4.61585H120.149V2.81875C120.149 2.54378 120.149 2.00367 120.189 1.5814H120.159C120.061 1.97421 119.953 2.39648 119.825 2.81875L119.273 4.61585H118.574L118.013 2.81875C117.865 2.34738 117.777 2.02331 117.678 1.5814H117.649C117.678 2.03313 117.698 2.58306 117.698 2.81875V4.61585H117.018V0.992188H118.052Z"
                    fill="#1D1D1B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_756_1624">
                    <rect width="165" height="143" fill="white" transform="translate(0 0.992188)" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
